import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.css';

import Icon from "../media/icons.jsx";
import AxipaysFull_log from '../media/axipays-full-logo.webp';

function Sidebar() {
    const [activeItem, setActiveItem] = useState("Introduction");
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleItemClick = (itemName, isSubmenuItem = false) => {
        if (isSubmenuItem) {
            setActiveItem(itemName);
        } else {
            setActiveItem(itemName);
            if (openDropdown === itemName) {
                setOpenDropdown(null);
            } else if (itemName === "Manage User") {
                setOpenDropdown(itemName);
            } else {
                setOpenDropdown(null);
            }
        }
    };

    const sidebarItems = [
        {
            section: "Getting started",
            items: [
                { name: "Introduction", link: "/", target:"_self" },
                // { name: "About Axipays", link: "/home", target:"_self" }
            ]
        },
        {
            section: "Payment Integration",
            items: [
                { name: "Server to server integration", link: "/s2sIntegration", target:"_self", dropdown:false, 
                    submenu: [
                        { name: "Initiate Transaction", link: "", disabled: true, target:"_self" },
                        { name: "Redirection", link: "", disabled: true, target:"_self" },
                        { name: "Get Transaction Status", link: "", disabled: true, target:"_self" },
                        { name: "Cancel Transaction", link: "", disabled: true, target:"_self" },
                    ] 
                },
                {
                    name: "S2S - Direct", icon: "group", disabled: true, dropdown: false, submenu: [
                        { name: "Initiate Transaction", link: "", disabled: true, target:"_self" },
                        { name: "Get Transaction Status", link: "", disabled: true, target:"_self" },
                        { name: "Cancel Transaction", link: "", disabled: true, target:"_self" },
                    ]
                },
                { name: "Frame", icon: "checkbook", link: "/home", disabled: true, target:"_self" },
                { name: "Hosted Checkout", icon: "apk_document", link: "/home", disabled: true, target:"_self" }
            ]
        },
        {
            section: "Dashboard Tour",
            items: [
                { name: "Overview", link: "/home", disabled: true, target:"_self" },
            ]
        }
    ];

    const SidebarItem = ({ item }) => {
        const handleClick = () => {
            if (!item.disabled) {
                handleItemClick(item.name);
            }
        };

        return item.disabled ? (
            <span className={`sidebar-item disabled`}>
                <span className='items'>{item.name}</span>
            </span>
        ) : (
            item.dropdown ? (
                <span 
                    className={`sidebar-item expandable ${activeItem === item.name ? "active" : ""}`}
                    onClick={handleClick}
                >
                    <span className='items'>{item.name}</span>
                </span>
            ) : (
                <Link to={item.link} target={item.target} className={`sidebar-item ${activeItem === item.name ? "active" : ""}`} onClick={handleClick}>
                    <span className='items'>{item.name}</span>
                </Link>
            )
        );
    };

    return (
        <>
            <div className='api-sidebar'>
                <div className='sidebar-header'>
                    <img src={AxipaysFull_log} alt='Axipays full logo' />
                </div>
    
                <div className='sidebar-body'>
                    <div className='scrollable-content'> 
                        {sidebarItems.map(section => (
                            <div key={section.section} className="sidebar-section">
                                <h4 className="section-title">{section.section}</h4>
                                {section.items.map(item => (
                                    <div key={item.name}>
                                        <SidebarItem item={item} />
                                        {item.dropdown && (
                                            <div className={`submenu ${openDropdown === item.name ? 'open' : 'closed'}`}>
                                                {item.submenu.map(subItem => (
                                                    <Link
                                                        key={subItem.name}
                                                        to={subItem.link}
                                                        className={`submenu-item ${activeItem === subItem.name ? "active" : ""}`}
                                                        onClick={() => handleItemClick(subItem.name, true)} 
                                                    >
                                                        <span>{subItem.name}</span>
                                                    </Link>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
    
                <div className='sidebar-footer'></div>
            </div>
        </>
    )
}

export default Sidebar;
