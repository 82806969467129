import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from './components/404NotFound.jsx';

import './styles/styles.css'
import Apidoc from "./pages/Apidoc.jsx";

import S2S from "./pages/S2S.jsx";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Apidoc />} />
					<Route path="*" element={<NotFound />} />
					<Route path="/s2sIntegration" element={<S2S />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
