import React from 'react';
import Icon from '../media/icons';

function Code() {
    return (
        <>
            <div className='codeBlock'>
                <p className='codeBlock-heading'>
                    URL
                </p>
                <div className='codeBlock-header'>
                    <div className='dummy-threeDots'>
                        <div className='dot1'></div>
                        <div className='dot2'></div>
                        <div className='dot3'></div>
                    </div>
                    <div className='codeBlock-header-Actions'>
                        <Icon name="light_mode" width={25} height={25} color='#00264c' className='ic'/>
                        <Icon name="copy" width={25} height={25} color='#00264c' className='ic'/>
                    </div>
                </div>
                <div className='codeBlock-body'>
                    <p>https:\\api.axipays.com</p>
                </div>
            </div> 
        </>
    )
}

export default Code