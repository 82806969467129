import React from 'react';
import ShortheaderImg from '../media/Frame 1321316231.png'

function ShortHeader() {
    return (
        <>
            <div className='ShortHeader'>
                <div className='headerPath'>
                    <p>path</p>
                </div>
                <div className='shortheader-main'>
                    <h1>Server to server Integration</h1>
                    <img src={ShortheaderImg} alt='Header image'></img>
                </div>
            </div>
        </>
    )
}

export default ShortHeader