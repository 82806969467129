import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/Sidebar.jsx';
import headerInfograph from '../media/apiDoc-header-infograph.gif';

function Apidoc() {
    const [activeSection, setActiveSection] = useState('');

    // Create refs for each section
    const aboutRef = useRef(null);
    const missionRef = useRef(null);
    const featuresRef = useRef(null);
    const benefitsRef = useRef(null);
    const gettingStartedRef = useRef(null);
    const supportRef = useRef(null);
    const conclusionRef = useRef(null);

    useEffect(() => {
        // Sections array to map refs with IDs
        const sections = [
            { id: 'about-axipays', ref: aboutRef },
            { id: 'mission-vision', ref: missionRef },
            { id: 'platform-features', ref: featuresRef },
            { id: 'benefits', ref: benefitsRef },
            { id: 'getting-started', ref: gettingStartedRef },
            { id: 'support', ref: supportRef },
            { id: 'conclusion', ref: conclusionRef },
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 } // Adjust to detect when 60% of section is in view
        );

        // Observe each section
        sections.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up observer on component unmount
        return () => {
            sections.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    
    return (
        <>
            <Sidebar />
            <div className="apidoc">
                <div className="apidoc-header">
                    <div className="apidoc-header-headline">
                        <p>Get started</p>
                        <h1>Introduction</h1>
                    </div>
                    <div className="apidoc-header-infograph">
                        <img src={headerInfograph} alt="Header Infograph" />
                    </div>
                </div>
                <div className="apidoc-body">
                    <div className="apidoc-body-mainContent">
                        <p className="apidoc-note">
                            AXIPAYS provides a foundation that empowers merchants to gather and distribute payments globally, seamlessly 
                            utilizing the preferred payment channels at any given location and time.
                        </p>

                        <div className="bodyContent">
                            <h2 ref={aboutRef} id="about-axipays" className="apiDoc-heading">About AXIPAYS</h2>
                            <p className="bodyContent-para">
                                <p>
                                    Welcome to AXIPAYS, a comprehensive payment gateway solution that is redefining digital transactions for 
                                    businesses worldwide. AXIPAYS is crafted to be more than just a transactional service—it’s a robust, 
                                    reliable, and scalable platform that enables businesses to handle financial transactions with ease and 
                                    security. At AXIPAYS, we understand the importance of a seamless, secure payment experience in today’s 
                                    competitive digital landscape, and our mission is to provide a platform that meets these expectations for 
                                    businesses of every size and sector.
                                </p>
                                &nbsp;
                                <p>
                                    Currently, AXIPAYS specializes in providing Server-to-Server (S2S) Payment Processing, an approach that
                                    allows for direct, secure communication between merchants’ servers and our payment infrastructure. This 
                                    initial focus on S2S transactions empowers businesses by providing a backend solution that doesn’t require
                                    extensive user interface work, allowing our merchants to retain full control over the user experience, 
                                    transaction logic, and design.
                                </p>
                            </p>

                            <h2 ref={missionRef} id="mission-vision" className="apiDoc-heading">Mission and Vision</h2>
                            <p className="bodyContent-para">
                                At the heart of AXIPAYS lies a clear mission and vision that guides everything we do. Our commitment is to create 
                                and maintain a seamless, secure payment gateway that simplifies the complexities of digital payments for 
                                businesses around the globe.
                                <br></br>
                                <br></br>
                                Mission: AXIPAYS is dedicated to building a reliable and accessible payment ecosystem for businesses, ensuring 
                                they can operate confidently and securely. Our mission is to provide a suite of robust, developer-friendly 
                                payment tools that adapt to the growing needs of digital commerce, helping businesses unlock new opportunities 
                                through innovative payment solutions.
                                <br></br>
                                <br></br>
                                Vision: AXIPAYS envisions a world where every business, regardless of size, can harness the power of digital 
                                payments with ease and security. Our goal is to become a global leader in payment processing, offering solutions 
                                that integrate effortlessly into any business model and scale with each company’s growth.
                            </p>

                            <h ref={featuresRef} id="platform-features" className="apiDoc-heading">Platform Features</h>
                            <p className="bodyContent-para">
                                AXIPAYS is not just a payment gateway; it is a comprehensive solution crafted to address the demands of 
                                today’s digital economy. Here’s a breakdown of some of the core attributes that set AXIPAYS apart:
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>a. Dedicated to S2S Payment Processing</h3>
                                AXIPAYS’ Server-to-Server (S2S) model is ideal for businesses that want to handle payments directly from 
                                their backend, without reliance on frontend interfaces. This approach provides greater control over each 
                                transaction, ensures efficient data handling, and allows businesses to implement a fully customized checkout 
                                experience that aligns with their brand and user experience requirements.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>b. Seamless Scalability</h3>
                                Our infrastructure is designed to support businesses of all sizes. Whether you’re processing hundreds or 
                                millions of transactions, AXIPAYS scales effortlessly to meet growing demand. Our platform grows with your 
                                business, adapting to traffic and transaction spikes with minimal latency and maximum reliability.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>c. Security-Centric Design</h3>
                                In today’s digital landscape, security is paramount. AXIPAYS integrates advanced encryption, secure 
                                authentication protocols, and real-time monitoring systems to safeguard each transaction. Our compliance with 
                                global payment standards ensures that sensitive data remains protected, helping merchants to build trust with 
                                their customers.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>d. Simple, Intuitive Integration</h3>
                                We understand that integrating a new payment gateway can be a daunting task. That’s why the AXIPAYS API is 
                                designed to be developer-friendly, with well-documented endpoints and intuitive workflows that make 
                                integration as straightforward as possible. Whether you’re working with a small team or a large-scale 
                                enterprise development team, our API provides the flexibility you need.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>e. 24/7 Support and Resources</h3>
                                AXIPAYS is more than a service; it’s a partnership. Our support team and extensive resource library are 
                                available to assist you at every step of your journey, from integration to optimization. Our dedicated 
                                support staff is trained to resolve issues swiftly, ensuring your transactions keep flowing smoothly.
                            </p>

                            <h2 ref={benefitsRef} id="benefits" className="apiDoc-heading">Key Benefits of AXIPAYS</h2>
                            <p className="bodyContent-para">
                                Choosing AXIPAYS as your payment partner brings a host of benefits designed to enhance your operations, 
                                optimize transaction efficiency, and elevate your brand.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>a. Enhanced Control and Flexibility</h3>
                                With our S2S model, businesses have complete control over the payment process. You can design the user flow 
                                to align precisely with your brand, tailor transaction handling to meet specific requirements, and manage 
                                data directly within your own system for improved accuracy and reporting.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>b. Unmatched Reliability and Speed</h3>
                                AXIPAYS leverages cutting-edge technology and robust infrastructure to deliver lightning-fast transaction 
                                processing and high availability. Our commitment to reliability ensures that your transactions are processed 
                                efficiently, minimizing downtime and ensuring your customers experience smooth, uninterrupted service.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>c. Stronger Security Measures</h3>
                                AXIPAYS understands that every transaction needs to be secure. From end-to-end encryption to PCI DSS 
                                compliance, we have implemented a host of security measures that not only protect sensitive data but also 
                                provide peace of mind to both you and your customers.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>d. Improved Customer Experience</h3>
                                Speed and reliability are at the core of AXIPAYS, helping to create a frictionless customer experience that 
                                encourages repeat business and builds trust. Your customers will benefit from faster transaction processing 
                                and fewer delays, making each interaction with your business more enjoyable.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>e. Extensive Documentation and Developer Resources</h3>
                                At AXIPAYS, we are committed to empowering your development team with the resources they need. Our 
                                comprehensive API documentation, SDKs, and developer guides provide in-depth explanations, examples, and 
                                troubleshooting tips, enabling you to integrate and manage payments with confidence.
                            </p>

                            <h2 ref={gettingStartedRef} id="getting-started" className="apiDoc-heading">Getting Started with AXIPAYS API</h2>
                            <p className="bodyContent-para">
                                AXIPAYS provides a streamlined API that allows for seamless integration into any system, from e-commerce 
                                platforms to custom business applications. This documentation is your roadmap to setting up and using the 
                                AXIPAYS API, starting with essential concepts and progressing to advanced use cases.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>a. API Overview</h3>
                                The API Overview section introduces the fundamental principles of the AXIPAYS API, outlining the key 
                                endpoints, authentication methods, and usage limits. This is the ideal starting point for understanding how 
                                to interact with our API effectively.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>b. Authentication and Authorization</h3>
                                Our authentication protocols are designed to ensure secure access for all requests to the AXIPAYS system. 
                                This section covers everything you need to know about token-based authentication, managing API keys, and 
                                handling permissions to keep your transactions secure.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>c. Managing Transactions</h3>
                                Learn how to create, authorize, and complete transactions using the AXIPAYS API. This section provides 
                                step-by-step instructions on managing transactions from start to finish, ensuring you can handle payments 
                                smoothly and efficiently.
                                <br></br>
                                <br></br>

                                <h3 className='secondHeading'>d. Error Handling and Troubleshooting</h3>
                                Even the most carefully crafted systems encounter issues from time to time. Our Error Handling and 
                                Troubleshooting section is designed to help you quickly identify and resolve common issues, ensuring minimal 
                                disruption to your operations.
                            </p>

                            <h2 ref={supportRef} id="support" className="apiDoc-heading">Technical Support and Customer Assistance</h2>
                            <p className="bodyContent-para">
                                AXIPAYS takes pride in providing exceptional customer support. Our dedicated support team is available 24/7 
                                to assist with any technical questions or issues you may encounter. From integration support to resolving 
                                technical issues, our team is ready to ensure a smooth experience. Additionally, AXIPAYS provides a detailed 
                                knowledge base and community forums, where you can find answers to frequently asked questions, connect with 
                                other users, and share best practices.
                            </p>

                            <h2 ref={conclusionRef} id="conclusion" className="apiDoc-heading">Conclusion</h2>
                            <p className="bodyContent-para">
                                AXIPAYS is more than just a payment gateway; it’s a comprehensive partner in your business’s growth. By 
                                focusing on the essentials of server-to-server payment processing, we enable businesses to take full control 
                                of their payment experience, backed by unmatched reliability, security, and scalability. Our goal is to 
                                empower you with the tools, support, and infrastructure necessary to elevate your payment processes and drive 
                                customer satisfaction. AXIPAYS is here to grow with you, and we look forward to being a trusted part of your 
                                journey in digital commerce.
                            </p>
                        </div>
                    </div>

                    <div className="apidoc-body-tableContent">
                        <div className="bodyOverview-table">
                            <h4>Table of Content</h4>
                            <div className="bodyOverview-tableMenu">
                                <ul className="bodyOverview-table-Submenu">
                                    <li>Axipays Introduction</li>
                                    <li>Getting started</li>
                                    <div className="bodyOverview-table-Submenu-items">
                                        <li><a href="#about-axipays"  className={activeSection === 'about-axipays' ? 'active' : ''} >About AXIPAYS</a></li>
                                        <li><a href="#mission-vision" className={activeSection === 'mission-vision' ? 'active' : ''}>Mission and Vision</a></li>
                                        <li><a href="#platform-features" className={activeSection === 'platform-features' ? 'active' : ''}>Platform Features</a></li>
                                        <li><a href="#benefits" className={activeSection === 'benefits' ? 'active' : ''}>Benefits of Choosing AXIPAYS</a></li>
                                        <li><a href="#getting-started" className={activeSection === 'getting-started' ? 'active' : ''}>Getting Started with AXIPAYS API</a></li>
                                        <li><a href="#errorHandling" className={ supportRef === 'support' ? 'active' : ''}>Technical Support and Customer Assistance</a></li>
                                        <li><a href="#conclusion" className={ conclusionRef === 'conclusion' ? 'active' : ''}>conclusion</a></li>
                                    </div>
                                </ul>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Apidoc;
