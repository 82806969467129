import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ShortHeader from '../components/ShortHeader';
import Code from '../components/Code';

function S2S() {

    const [activeSection, setActiveSection] = useState('');

    // Create refs for each section
    const aboutRef = useRef(null);
    const missionRef = useRef(null);
    const featuresRef = useRef(null);
    const benefitsRef = useRef(null);
    const gettingStartedRef = useRef(null);
    const supportRef = useRef(null);
    const conclusionRef = useRef(null);

    useEffect(() => {
        // Sections array to map refs with IDs
        const sections = [
            { id: 'about-axipays', ref: aboutRef },
            { id: 'mission-vision', ref: missionRef },
            { id: 'platform-features', ref: featuresRef },
            { id: 'benefits', ref: benefitsRef },
            { id: 'getting-started', ref: gettingStartedRef },
            { id: 'support', ref: supportRef },
            { id: 'conclusion', ref: conclusionRef },
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.6 } // Adjust to detect when 60% of section is in view
        );

        // Observe each section
        sections.forEach(({ ref }) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Clean up observer on component unmount
        return () => {
            sections.forEach(({ ref }) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <>
            <ShortHeader />
            <Sidebar />
            <div className='mainContainer'>
                <div className='mainContainer-Content'>
                    <div className='page-content'>
                        <h2></h2>
                        <p>Enable your merchant account to receive payments on your terms by leveraging our diverse payment options. Seamlessly accept funds from individuals worldwide through our supported payment methods, including mobile money, cards, and bank transfers.</p>

                        <h2>Step For Receiving Money</h2>
                        <div className='page-content-pointers'>
                            <div className='Steps'>
                                <h3>1. Create Request Data (JSON)</h3>
                                <p>Creating JSON request data entails organizing information in key-value pairs within curly braces.</p>
                                <Code/>
                            </div>
                            <div className='Steps'>
                                <h3>2. Set Relevant Request Headers</h3>
                                <p>Understanding API specifications and using proper content type headers is crucial for effective data transmission.</p>
                                <div>
                                    <p><i>Content-Type:</i> application/json</p>
                                    <p><i>Accept:</i> application/json</p>
                                    <p><i>Authorization:</i> Use the access token generated from the authorization API.</p>
                                </div>
                                <Code/>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="apidoc-body-tableContent">
                        <div className="bodyOverview-table">
                            <h4>Table of Content</h4>
                            <div className="bodyOverview-tableMenu">
                                <ul className="bodyOverview-table-Submenu">
                                    <li>Axipays Introduction</li>
                                    <li>Getting started</li>
                                    <div className="bodyOverview-table-Submenu-items">
                                        <li><a href="#about-axipays"  className={activeSection === 'about-axipays' ? 'active' : ''} >About AXIPAYS</a></li>
                                        <li><a href="#mission-vision" className={activeSection === 'mission-vision' ? 'active' : ''}>Mission and Vision</a></li>
                                        <li><a href="#platform-features" className={activeSection === 'platform-features' ? 'active' : ''}>Platform Features</a></li>
                                        <li><a href="#benefits" className={activeSection === 'benefits' ? 'active' : ''}>Benefits of Choosing AXIPAYS</a></li>
                                        <li><a href="#getting-started" className={activeSection === 'getting-started' ? 'active' : ''}>Getting Started with AXIPAYS API</a></li>
                                        <li><a href="#errorHandling" className={ supportRef === 'support' ? 'active' : ''}>Technical Support and Customer Assistance</a></li>
                                        <li><a href="#conclusion" className={ conclusionRef === 'conclusion' ? 'active' : ''}>conclusion</a></li>
                                    </div>
                                </ul>     
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default S2S